import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {NgEventBus} from 'ng-event-bus';

import {StoreService} from './store.service';
import {ToastService} from './toast.service';
import {WebPushNotificationService} from './web-push-notification.service';
import {AuthDTO} from '../../../models/dto/auth-dto';
import {BusEvents} from '../../../models/enums/bus-events';
import {ApiResponse} from '../../../models/api-response';
import {Auth} from '../../../models/auth';
import {AppLogger} from '../../../utils/app-logger';

declare const window: any;

@Injectable()
export class SessionService {

  constructor(private storeService: StoreService,
              private webPushNotificationService: WebPushNotificationService,
              private eventBus: NgEventBus,
              private toastService: ToastService,
              private router: Router) {

  }

  public login(r: ApiResponse<AuthDTO>, fromRegister = false): void {
    const auth: Auth = {
      id: r.data.id,
      at: r.data.accessToken,
      rt: r.data.refreshToken,
      sc: r.data.scope,
      ea: r.data.expiresAt
    };

    this.storeService.setAuth(auth);
    this.webPushNotificationService.start();
    this.eventBus.cast<{ name: string, email: string }>(BusEvents.APP_SESSION, {
      name: r.data.name,
      email: r.data.email
    });

    if (fromRegister) {
      this.toastService.show('Te has registrado correctamente', 2500);
    }

    this.router.navigateByUrl('events').finally();
    AppLogger.info('[Session] User session started!');
  }

  public logout(redirect = true): void {
    this.storeService.deleteAuth();
    AppLogger.info('[Session] User session ended!');

    if (redirect) {
      setTimeout(() => window.location.href = `${window.location.protocol}//${window.location.host}/login`, 100);
    }
  }

}
