import {Injectable, NgZone} from '@angular/core';
import {SwPush} from '@angular/service-worker';

import {NgEventBus} from 'ng-event-bus';

import {env} from '../../../../environments/env';

import {ToastService} from './toast.service';
import {WebPushService} from './web-push.service';
import {StoreService} from './store.service';
import {SafariRemoteNotificationPermissionDTO} from '../../../models/dto/safari-remote-notification-permission-dto';
import {NotificationClickDTO} from '../../../models/dto/notification-click-dto';
import {PushDeviceCreateDTO} from '../../../models/dto/push-device-create-dto';
import {PushDeviceDTO} from '../../../models/dto/push-device-dto';
import {DeviceType} from '../../../models/enums/device-type';
import {BusEvents} from '../../../models/enums/bus-events';
import {ToastOption} from '../../../models/enums/toast-option';
import {ApiResponse} from '../../../models/api-response';
import {Auth} from '../../../models/auth';
import {WebPush} from '../../../models/web-push';
import {AppUtils} from '../../../utils/app-utils';
import {AppLogger} from '../../../utils/app-logger';

declare const window: any;

@Injectable()
export class WebPushNotificationService {

  private webPush: WebPush;

  constructor(private swPush: SwPush,
              private ngZone: NgZone,
              private toastService: ToastService,
              private storeService: StoreService,
              private eventBus: NgEventBus,
              private webPushService: WebPushService) {

    this.webPush = {
      isFlowStarted: false,
      isActive: false,
      isBlocked: false,
      wasValidated: false,
      wasDataSent: false
    };
  }

  public start(): void {
    AppLogger.info('[WPN] Starting WPN...');

    if (!this.isValidUserSession()) {
      setTimeout(() => this.start(), 5000);
      return;
    }

    if (!this.isFlowStarted()) {
      this.startPushNotificationFlow();
    }
  }

  private isValidUserSession(): boolean {
    AppLogger.info('[WPN] Validating current user session...');
    const auth: Auth | undefined = this.storeService.getAuth();

    if (!auth) {
      AppLogger.info('[WPN] User session not exists, trying again!');
      return false;
    }

    return true;
  }

  private isFlowStarted(): boolean {
    if (!this.webPush.isFlowStarted) {
      this.webPush = {...this.webPush, isFlowStarted: true};
      return false;
    } else {
      AppLogger.info('[WPN] Process already initialized');
      return true;
    }
  }

  private startPushNotificationFlow(): void {
    AppLogger.info('[WPN] Started push notification flow: OK');

    if ('serviceWorker' in navigator && 'PushManager' in window) {
      this.checkNormalNotificationSupport();
    } else if ('safari' in window && 'pushNotification' in window.safari) {
      this.checkSafariNotificationSupport();
    } else {
      AppLogger.warn('[WPN] Service Worker or Push Manager or Safari is not supported');
      return;
    }

    setTimeout(() => this.checkNotificationClick(), 10)
  }

  private checkNormalNotificationSupport(): void {
    AppLogger.info('[WPN] Browser serviceWorker and PushManager support: OK');

    if (!this.swPush.isEnabled) {
      AppLogger.warn('[WPN] Service worker is not active, cannot subscribe to push notifications');
      return;
    }

    AppLogger.info('[WPN] Service worker: OK');

    if (Notification.permission === 'denied') {
      this.webPush = {...this.webPush, isActive: false, isBlocked: true, wasValidated: true};
      AppLogger.warn('[WPN] Push Notifications are blocked in browser');
      return;
    }

    if (Notification.permission === 'granted') {
      AppLogger.info('[WPN] Push Notification permission: OK');
      this.authorize();
      return;
    }

    AppLogger.info('[WPN] Push Notification permission: NOT GRANTED');
    const difference: number = AppUtils.canShowNotificationPermission(this.storeService.getNextNotification());

    if (difference > 0) {
      AppLogger.info(`[WPN] User cannot be interrupted with push notifications until: ${new Date(difference * 1000).toISOString()}`);
      return;
    }

    AppLogger.info('[WPN] Show push notification permission toast');
    this.toastService.observe('¿Deseas activar las notificaciones?', 120000, 'SI', 'NO')
      .subscribe((r: ToastOption) => {
        if (r === ToastOption.OPTION_1) this.permissionGranted();
        if (r === ToastOption.OPTION_2) this.hide();
      });
  }

  private checkSafariNotificationSupport(): void {
    AppLogger.info('[WPN] Browser Safari and PushNotification support: OK');
    const permissionData: SafariRemoteNotificationPermissionDTO = window.safari.pushNotification.permission(env.safari.id);

    if (permissionData !== null && permissionData !== undefined) {
      this.checkSafariPermission(permissionData);
    } else {
      AppLogger.warn('[WPN] Permission data is null or undefined in Safari browser');
    }
  }

  private checkNotificationClick(): void {
    AppLogger.info('[WPN] Started check push notification click flow: OK');

    let notificationId = AppUtils.getUrlParameter(this.storeService.getGeneral().search, 'notification_id');

    if (notificationId !== null && notificationId !== undefined && String(notificationId).trim().length > 0) {
      AppLogger.info('[WPN] Detected notification ID in URL, processing...');

      notificationId = String(notificationId).trim();
      const notificationClick: NotificationClickDTO = {
        deviceId: this.storeService.getInstallationId().id
      };

      AppLogger.info('[WPN] Sending push notification click to server...');
      this.webPushService.registerClick(notificationId, notificationClick)
        .subscribe((r: ApiResponse<string>) => {
          if (r.success) {
            AppLogger.info('[WPN] Push notification click registered in server!');
          } else {
            AppLogger.warn('[WPN] Push notification click could not be registered')
          }
        });
    } else {
      AppLogger.info('[WPN] Check push notification click finished: not detected notification_id parameter');
    }

  }

  private checkSafariPermission(permissionData: SafariRemoteNotificationPermissionDTO): void {

    if (permissionData.permission === 'denied') {
      this.webPush = {...this.webPush, isActive: false, isBlocked: true, wasValidated: true};
      AppLogger.warn('[WPN] Push Notifications are not allowed in Safari browser');
      return;
    }

    if (permissionData.permission === 'granted') {
      AppLogger.info('[WPN] Safari Push Notification permission: OK');
      this.checkSubscriptionSafari(permissionData);
      return;
    }

    AppLogger.info('[WPN] Safari Push Notification permission: DEFAULT');
    const difference: number = AppUtils.canShowNotificationPermission(this.storeService.getNextNotification());

    if (difference > 0) {
      AppLogger.warn(`[WPN] User cannot be interrupted with Safari push notifications until: ${new Date(difference * 1000).toISOString()}`);
      return;
    }

    AppLogger.info('[WPN] Can show Safari push notification permission toast: OK');

    AppLogger.info('[WPN] Show Safari push notification permission toast');
    this.toastService.observe('¿Deseas activar las notificaciones?', 120000, 'SI', 'NO')
      .subscribe((r: ToastOption) => {
        if (r === ToastOption.OPTION_1) this.permissionGrantedSafari();
        if (r === ToastOption.OPTION_2) this.hide();
      });
  }

  private authorize(): void {
    this.ngZone.run(() => {
      AppLogger.info('[WPN] Calling requestSubscription method...');

      this.swPush.requestSubscription({serverPublicKey: env.vapidPublicKey})
        .then((subscription: PushSubscription) => this.checkSubscription(subscription))
        .catch((err: any) => {
          AppLogger.error('[WPN] App could not suscribe to push notifications', err);
          this.validateError(err);
        });

    });
  }

  private permissionGranted(): void {
    if (window.umami) window.umami.track('authorize-notifications');

    AppLogger.info('[WPN] User wants to receive notifications, requesting permission from browser...');
    this.authorize();
  }

  private permissionGrantedSafari(): void {
    if (window.umami) window.umami.track('authorize-notifications-safari');

    AppLogger.info('[WPN] User wants to receive notifications, requesting permission from Safari browser...');
    this.authorizeSafari();
  }

  private authorizeSafari(): void {
    this.ngZone.run(() => {
      AppLogger.info('[WPN] Calling requestPermission method in Safari...');

      try {
        const data = {id: this.storeService.getInstallationId().id};
        window.safari.pushNotification.requestPermission(env.safari.url, env.safari.id, data, (permissionData: SafariRemoteNotificationPermissionDTO) => this.checkSubscriptionSafari(permissionData));
      } catch (err: any) {
        AppLogger.error('[WPN] App could not suscribe to Safari push notifications', err);
        this.toastService.show('Notificaciones están bloqueadas', 5000);
      }

    });
  }

  private validateError(err: any): void {
    if (err instanceof DOMException) {
      const e: DOMException = err;

      if (e.code === DOMException.INVALID_STATE_ERR && String(e.message).indexOf('different') > -1) {
        AppLogger.info('[WPN] Detected error related to change in VAPID keys, unsubscribing...');

        this.swPush.unsubscribe()
          .then(() => {
            AppLogger.info('[WPN] Successfully unsubscribe different applicationServerKey, restarting Push notification flow...');
            this.startPushNotificationFlow();
          })
          .catch((err: any) => AppLogger.error('[WPN] Unsubscribe operation failed', err));
      } else {
        this.webPush = {...this.webPush, isActive: false, isBlocked: false, wasValidated: true};
      }
    } else {
      this.webPush = {...this.webPush, isActive: false, isBlocked: false, wasValidated: true};
    }

  }

  private checkSubscription(subscription: PushSubscription): void {
    AppLogger.info('[WPN] Received permission from browser, initiating subsequent validations..');

    let itsDifferent = false;

    if (subscription !== null) {

      // Check if VAPID of push subscription is the same as the application uses
      if (subscription.options.applicationServerKey !== null) {
        const ask: string = AppUtils.baToStr(subscription.options.applicationServerKey);
        const current: string = env.vapidPublicKey;

        if (ask !== current) {
          itsDifferent = true;
          AppLogger.warn(`[WPN] Different applicationServerKey installed, restarting (obtained: ${ask}, current: ${current})...`);

          this.swPush.unsubscribe()
            .then(() => {
              AppLogger.info('[WPN] Successfully unsubscribe different applicationServerKey, restarting push notification flow...');
              this.startPushNotificationFlow();
            })
            .catch((err: any) => AppLogger.error('[WPN] Unsubscribe operation failed', err));
        } else {
          AppLogger.info('[WPN] Installed applicationServerKey is the same of current app: OK');
        }
      }

    }

    this.webPush = {
      ...this.webPush,
      isActive: !(subscription === null || itsDifferent),
      isBlocked: (Notification.permission === 'denied'),
      wasValidated: true
    };

    if (this.webPush.isActive) {
      this.saveWPD(JSON.stringify(subscription), DeviceType.GENERAL);
    } else {
      if (this.webPush.isBlocked) {
        AppLogger.info('[WPN] Push are blocked in browser', Notification.permission);
      } else {
        AppLogger.info('[WPN] User is not subscribed to push');
      }
    }

  }

  private checkSubscriptionSafari(permissionData: SafariRemoteNotificationPermissionDTO): void {
    AppLogger.info('[WPN] Received permission from Safari browser, initiating subsequent validations...');

    this.webPush = {
      ...this.webPush,
      isActive: permissionData.permission === 'granted' && permissionData.deviceToken !== null,
      isBlocked: permissionData.permission === 'denied',
      wasValidated: true
    };

    const data = String(permissionData.deviceToken || '');

    if (this.webPush.isActive) {
      this.saveWPD(data, DeviceType.SAFARI);
    } else {
      if (this.webPush.isBlocked) {
        AppLogger.info('[WPN] Push are not allowed in Safari browser:', permissionData);
      } else {
        AppLogger.info('[WPN] User is not subscribed to push in Safari');
      }
    }

  }

  private hide(): void {
    const date: Date = new Date();
    const currentUnix: number = Math.round(date.getTime() / 1000.0);
    this.storeService.setNextNotification({ti: currentUnix});

    const nextNotification: string = new Date((currentUnix * 1000) + (AppUtils.TIME_TO_NOTIFY_PUSH * 1000)).toISOString();
    AppLogger.info(`[WPN] User doesnt want to activate push notifications, next notification: ${nextNotification}`);
  }

  private saveWPD(data: string, type: DeviceType): void {

    if (this.webPush.wasDataSent) {
      AppLogger.warn('[WPN] Info about Push Notification has already been previously sent to the server');
      return;
    }

    AppLogger.info('[WPN] Sending info about Push Notifications for this device to server...');

    const auth: Auth | undefined = this.storeService.getAuth();

    if (auth !== undefined && auth.id) {
      const installationId: string = this.storeService.getInstallationId().id;

      const pushDevice: PushDeviceCreateDTO = {
        id: installationId,
        userId: auth.id,
        type: type,
        data,
        subscribed: true,
        userAgent: navigator.userAgent || ''
      };

      this.webPushService.registerDevice(pushDevice)
        .subscribe((r: ApiResponse<PushDeviceDTO>) => {
            if (!r.success) {
              AppLogger.warn('[WPN] Push device could not be registered')
              return;
            }

            this.webPush = {...this.webPush, wasDataSent: true};
            AppLogger.info('[WPN] Push device registered/updated');
            this.eventBus.cast(BusEvents.APP_WEB_PUSH);
          }
        );
    } else {
      AppLogger.warn('[WPN] User session not exists, retrying in 5 seconds...');
      setTimeout(() => this.saveWPD(data, type), 5000);
    }
  }
}
